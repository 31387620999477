import '../App.css';
import {getList, createListItem, updateListItem, deleteListItem, deleteList} from '../commonActions';
import {Modal, Button, Cards, Header, SpaceBetween, Box, Input} from "@cloudscape-design/components";
import { useEffect, useState } from 'react';

function List(props) {
		const [list, setList] = useState([]);
		const [newItemName, setNewItemName] = useState('');
		const [deleteListModalVisible, setDeleteListModalVisible] = useState(false);
		const [ListActionModalVisible, setListActionsModalVisible] = useState(false);

		async function getItemList() {
			let res = await getList(props.listObj, props.client);
			setList(res);
		}

		function generateList(list) {
			let displayList = list.sort((a, b) => (a.enabled > b.enabled) ? 1 : -1);
			return displayList.filter(item => item?.Name.toLowerCase().includes(newItemName.toLowerCase()));
		};

		async function addItem(name) {
			if(name.length > 0) {
				let res = await createListItem(props.listObj.id, name, props.client);
				getItemList();
				setNewItemName('');
			}
		}

		async function deleteItem(item) {
			let res = await deleteListItem(item, props.client)
			getItemList();
		}

		async function updateItem(item) {
			let res = await updateListItem(item, props.client)
			getItemList();
			
		}
		
		useEffect(() => {
				getItemList();
		}, [])

	return (
		<div className="List">
			<Cards
				ariaLabels={{
					itemSelectionLabel: (e, t) => `select ${t.name}`,
					selectionGroupLabel: "Item selection"
				}}
				cardDefinition={{
					header: (item) => {
						return <>
							<div class="itemCardContent">
								{!item.enabled ? item.Name : <span className='disabledItemCardName'>{item.Name}</span>}
								<>
								<Box float='right'>
									<SpaceBetween direction='horizontal' size="xs">
										<Button iconName={item.enabled ? "refresh" : "check"} onClick={() => updateItem(item)}></Button>
										<Button iconName="remove" onClick={() => deleteItem(item)}></Button>
									</SpaceBetween>
								</Box>
								</>
							</div>
						</>
					}
				}}
				cardsPerRow={[
					{ cards: 1 },
					{ minWidth: 500, cards: 1 }
				]}
				items={generateList(list)}
				loadingText="Loading resources"
				empty={
					<Box textAlign="center" color="inherit">
						<b>No items</b>
						<Box
							padding={{ bottom: "s" }}
							variant="p"
							color="inherit"
						>
						There are no items in this list, why not add one?
						</Box>
					</Box>
				}
				header={
				<>
				<Header>
					{props.listObj.Name}
					<Button iconName="remove" variant="inline-icon" onClick={() => setDeleteListModalVisible(true)}/>
					<Button iconName="ellipsis" variant="inline-icon" onClick={() => setListActionsModalVisible(true)}/>
				</Header>
				<Box>
					<SpaceBetween size="xs">
						<Input
							type="text"
							placeholder='New Item'
							value={newItemName}
							onChange={({ detail }) => setNewItemName(detail.value)}
						/>
						<Button disabled={newItemName.length < 1 || newItemName.length > 50} onClick={() => addItem(newItemName)} fullWidth>Add</Button>
					</SpaceBetween>
				</Box>
				</>
				
				}
			/>
			<Modal
				onDismiss={() => setDeleteListModalVisible(false)}
				visible={deleteListModalVisible}
				footer={
					<Box float="right">
					<SpaceBetween direction="horizontal" size="xs">
						<Button variant="link" onClick={() => setDeleteListModalVisible(false)}>Cancel</Button>
						<Button variant="primary" onClick={async () => {
					let res = await deleteList(props.listObj, props.client);
					props.returnFunction();
					}}>Ok</Button>
					</SpaceBetween>
					</Box>
				}
				header="Are you sure?"
				>
				Deleting this list will delete all the items in it and every member will lose access. 
			</Modal>
			<Modal
				onDismiss={() => setListActionsModalVisible(false)}
				visible={ListActionModalVisible}
				footer={
					<div class="itemCardContent">
						Test
						<>
						<Box float='right'>
							<SpaceBetween direction='horizontal' size="xs">
								<Button fullWidth onClick={() => setListActionsModalVisible(false)}>Cancel</Button>
							</SpaceBetween>
						</Box>
						</>
					</div>
				}
				header="List actions"
				>
				Here you can edit list options.
			</Modal>
		</div>
	);
}

export default List;
