// Logs the user in and stores JWT in localstorage
export async function login(email, password, client) {
    return client.collection('users').authWithPassword(email, password);
}

// Creates a user
export async function register(email, password, passwordConfirm, client) {
    const data = {
        "email": email,
        "password": password,
        "passwordConfirm": passwordConfirm
    };
    return client.collection('users').create(data);
}

// Logs the user out
export async function logout(client) {
    return client.authStore.clear();
}

// List all lists that I have access to
export async function listLists(client) {
    return client.collection('Lists').getFullList({
        sort: '-created',
      });
}

// Returns a specific list
export async function getList(list, client) {
    return client.collection('Items').getFullList({
        filter: `Parent_List = "${list.id}"`
    });
    
}

// Creates a new list
export async function createList(listName, client) {
    return client.collection('Lists').create({
        "Name": listName,
        "Members": [
            client.authStore.model.id
        ]
    });
}

// Deletes a list
export async function deleteList(list, client) {
    return client.collection('Lists').delete(list.id);
}

// Creates a list item
export async function createListItem(listId, itemName, client) {
    return client.collection('Items').create({
        "Name": itemName,
        "Parent_List": listId
    });
}

// Toggle list item
export async function updateListItem(item, client) {
    return client.collection('Items').update(item.id, {
        ...item,
        "enabled": !item.enabled
    });
}

// Delete list item
export async function deleteListItem(item, client) {
    return client.collection('Items').delete(item.id);
}