import '../App.css';
import {listLists, logout, createList} from '../commonActions';
import List from './List';
import {Button, Cards, Box, Header, SpaceBetween, Modal, FormField, Input} from "@cloudscape-design/components";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ListView(props) {
    const [lists, setLists] = useState([]);
    const [selectedList, setSelectedList] = useState('');
    const [isLoading, setIsLoading] = useState(true);
	const [visible, setVisible] = useState(false);
	const [newListName, setNewListName] = useState('');
	const navigate = useNavigate();

	async function fetchLists() {
		let res = await listLists(props.client);
		setLists(res);
		setIsLoading(false);
	}
	

	function backToLists() {
		setSelectedList('');
		fetchLists();
	}

	async function handleLogout() {
		await logout(props.client);
		navigate('/login', { replace: true });
	}

	function closeModal() {
		setVisible(false);
		setNewListName('');
	}

	async function newList(listName) {
		let res = await createList(listName, props.client);
		setVisible(false);
		fetchLists();
	}

    useEffect(() => {
		if(!props.client.authStore.isValid)
			navigate('/login', { replace: true });
        fetchLists();
    }, [])

  return (
    <div className="ListView">
        {selectedList ? 
        <>
			<Box margin={{ bottom: "l" }} padding={{ bottom: "l" }}>
				<SpaceBetween direction='vertical' size="s">
					<List 
						client={props.client}
						listObj={selectedList}
						returnFunction={backToLists}
					/>
					<Button
						fullWidth
						onClick={() => setSelectedList(undefined)}>
						Back to Lists
					</Button> 
				</SpaceBetween>
			</Box>
		</> :
        <>
			<Cards
				ariaLabels={{
					itemSelectionLabel: (e, t) => `select ${t.Name}`,
					selectionGroupLabel: "Item selection"
				}}
				cardDefinition={{
					header: (item) => item.Name,
					sections:[{
						id: item => item.id,
						content: item => <Button
							fullWidth
							onClick={() => setSelectedList(item)}
						>
						Open List
						</Button> 
					},]
				}}
				cardsPerRow={[
					{ cards: 1 },
					{ minWidth: 500, cards: 1 }
				]}
				items={lists}
				loading={isLoading}
				loadingText="Loading resources"
				empty={
					<Box textAlign="center" color="inherit">
						<b>You don't have any lists</b>
						<Box
							padding={{ bottom: "s" }}
							variant="p"
							color="inherit"
						>
							Create one to start sharing it with others.
						</Box>
					</Box>
				}
				header={<Header>{'My Lists 📝'}</Header>}
			/>
			<Box>
					<SpaceBetween direction="vertical" size="s">
						<Button fullWidth variant='primary' onClick={() => setVisible(true)}>
							New List
						</Button>
						<Button fullWidth variant="link" onClick={() => handleLogout()}>
							Logout
						</Button>
					</SpaceBetween>
				</Box>
		</>}
		<Modal      
			onDismiss={() => closeModal()}
			visible={visible}
			footer={
				<Box float="right">
					<SpaceBetween direction="horizontal" size="xs">
						<Button variant="link" onClick={() => closeModal()}>Cancel</Button>
						<Button variant="primary" disabled={newListName.length < 1 || newListName.length > 50} onClick={() => newList(newListName)}>Ok</Button>
					</SpaceBetween>
				</Box>
			}
			header="New list">
		<FormField               
			description="Choose something descriptive, or don't 🤷‍♂️">
			<Input 
				value={newListName}
				placeholder={'List name'}
				onChange={({ detail }) => setNewListName(detail.value)}
			/>
		</FormField>
		</Modal>
    </div>
  );
}

export default ListView;
