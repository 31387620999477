import './App.css';
import PocketBase from 'pocketbase';
import Login from './Components/Login';
import Register from './Components/Register';
import ListView from './Components/ListView';
import { BrowserRouter, Routes, Route } from "react-router-dom";


const client = new PocketBase('https://api.aitemu.link');
// globally disable auto cancellation
client.autoCancellation(false);

function App() {
  return (
    <div className="App">
		<BrowserRouter>
        <Routes>
          <Route path="/" element={ <Login client={client}/>} />
          <Route path="/login" element={ <Login client={client}/>} />
          <Route path="/register" element={<Register client={client}/>} />
          <Route path="/listview" element={<ListView client={client}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
