import '../App.css';
import { Alert, Input, Button, Container, Header, Form, SpaceBetween } from "@cloudscape-design/components"
import { register } from '../commonActions';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
  client: any
}

function Register(props: Props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [errorBanner, setErrorBanner] = useState<errorBannerType>();
  const navigate = useNavigate();
  interface errorBannerType {
    title: string,
    body: any
  }
  
  function validateEmail(email: string):boolean {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async function handleRegister() {
    if(email === '' || password === '' || !validateEmail(email)) {
      setErrorBanner({
        title: 'Error',
        body: 'Invalid email or password'
      });
      return
    }
    if(password != passwordRepeat) {
      setErrorBanner({
        title: 'Error',
        body: 'Passwords do not match'
      });
      return
    }
    try {
      await register(email, password, passwordRepeat, props.client);
      navigate('/listview', { replace: true });
    } catch(e: any) {
      setErrorBanner({
        title: 'Error',
        body: e.response?.message || 'Could not register user'
      })
    }
  }

  useEffect(() => {
		if(props.client.authStore.isValid)
			navigate('/listview', { replace: true });
    }, [])

  return (
    <>
      <div className="Register">
          <h1><center>Aitemu</center></h1>
      </div>
    <Container header={<Header>Register</Header>}>
      <form onSubmit={e => e.preventDefault()}>
        <Form
          variant="embedded"
          actions={
            <SpaceBetween
              direction="horizontal"
              size="xs"
            >
              <Button variant="primary" fullWidth onClick={() => handleRegister()}>Register</Button>
              <Link to="/login"><Button fullWidth>Login</Button></Link>
            </SpaceBetween>
          }
        >
          <SpaceBetween
              direction="vertical"
              size="xs"
            >
            Email
            <Input
				type="text"
				placeholder='Email'
				autoFocus
				value={email}
				onChange={({ detail }) => setEmail(detail.value)}
				/>
			Password
			<Input
				type="password"
				placeholder='Password'
				autoFocus
				value={password}
				onChange={({ detail }) => setPassword(detail.value)}
			/>
      Repeat Password
			<Input
				type="password"
				placeholder='Password'
				autoFocus
				value={passwordRepeat}
				onChange={({ detail }) => setPasswordRepeat(detail.value)}
			/>
			{errorBanner ? <Alert
				statusIconAriaLabel="Error"
				type="error"
			>
                {errorBanner.body}
              </Alert> : <></>}
            </SpaceBetween>
        </Form>
      </form>
    </Container>
    </>
  );
}

export default Register;
