import '../App.css';
import { Alert, Box, Input, Button, Container, Header, Form, SpaceBetween, Modal, FormField} from "@cloudscape-design/components"
import { login } from '../commonActions';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
  client: any
}

function Login(props: Props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorBanner, setErrorBanner] = useState<errorBannerType>();
  const navigate = useNavigate();
  interface errorBannerType {
    title: string,
    body: any
  }
  

  async function handleLogin() {
    if(email === '' || password === '') {
      setErrorBanner({
        title: 'Error',
        body: 'Email or password cannot be empty'
      });
      return
    }
    try {
      await login(email, password, props.client);
      navigate('/listview', { replace: true });
    } catch(e: any) {
      setErrorBanner({
        title: 'Error',
        body: e.response?.message || 'Could not log in'
      })
    }
  }

  useEffect(() => {
		if(props.client.authStore.isValid)
			navigate('/listview', { replace: true });
    }, [])

  return (
    <>
      <div className="Login">
          <h1><center>Aitemu</center></h1>
      </div>
    <Container header={<Header>Login</Header>}>
      <form onSubmit={e => e.preventDefault()}>
        <Form
          variant="embedded"
          actions={
            <SpaceBetween
              direction="horizontal"
              size="xs"
            >
              <Button variant="primary" fullWidth onClick={() => handleLogin()}>Login</Button>
              <Link to="/register"><Button fullWidth>Register</Button></Link>
            </SpaceBetween>
          }
        >
          <SpaceBetween
              direction="vertical"
              size="xs"
            >
            Email
            <Input
				type="text"
				placeholder='Email'
				autoFocus
				value={email}
				onChange={({ detail }) => setEmail(detail.value)}
				/>
			Password
			<Input
				type="password"
				placeholder='Password'
				autoFocus
				value={password}
				onChange={({ detail }) => setPassword(detail.value)}
			/>
			{errorBanner ? <Alert
				statusIconAriaLabel="Error"
				type="error"
			>
                {errorBanner.body}
              </Alert> : <></>}
            </SpaceBetween>
        </Form>
      </form>
    </Container>
    </>
  );
}

export default Login;
